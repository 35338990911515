<template>
  <div class="traceability">
    <PageTopNav :tabsShow2="true" :tabList2="tabList2" @changeTab2="changeTab2">
    </PageTopNav>
    <router-view></router-view>
    <MyNav></MyNav>
  </div>
</template>

<script>
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTopNav: () => import("@/components/PageTopNav.vue"),
  },
  data() {
    return {
      tabList2: [
        {
          name: "溯源批次",
          title: "lot",
        },
        {
          name: "条码工单",
          title: "barCode",
        },
        {
          name: "信息录入",
          title: "infoInput",
        },

        {
          name: "信息编辑",
          title: "info",
        },
        {
          name: "溯源统计",
          title: "stat",
        },
        {
          name: "溯源上链",
          title: "upLink",
          icon: require("@/assets/image/traceability/trace_page_icon6.png"),
        },
      ],
    };
  },
  methods: {
    changeTab2(index) {
      this.$router.push({
        name: this.tabList2[index].title,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.traceability {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 0 0;

  .table-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #0e2138;
    padding: 37px 49px;
  }
}
</style>
